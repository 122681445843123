import { MenuItem } from './menus.model';
import { MenusModel } from './menus.state';

export class AddDataMenu {
  static readonly type = '[Menus] Add Data Menu';
  constructor(public dataMenu: MenusModel) {}
}

export class AddDataSidebar {
  static readonly type = '[Menus] Add Data Sidebar';
  constructor(public dataMenu: MenuItem[]) {}
}

export class AddDataTopbar {
  static readonly type = '[Menus] Add Data Topbar';
  constructor(public dataMenu: MenuItem[]) {}
}

export class AddDataUsermenu {
  static readonly type = '[Menus] Add Data Usermenu';
  constructor(public dataMenu: MenuItem[]) {}
}

export class ResetMenuState {
  static readonly type = '[Menus] Reset State';
}
