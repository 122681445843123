import { Component, OnInit, HostListener } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import {
  FILTERS_NAME,
  OnlineReviewsState,
  NPS_VALUES,
  ANSWER_VALUES,
} from 'app/states/online-reviews/online-reviews.state';
import { Observable, Subscription } from 'rxjs';
import { ClearFilterForApi, UpdateReviewsFilterForApi } from 'app/states/online-reviews/online-reviews.actions';
import { animation } from 'app/shared/utils/animation-custom';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { TranslateService } from '@ngx-translate/core';
import { OtasService } from 'app/online/otas/otas.service';
import { UtilService } from 'app/shared/util.service';
import { Ota } from 'app/new-fs/online/otas/models/Ota.model';

@Component({
  selector: 'mh-core-lib-new-reviews-filter',
  templateUrl: './new-reviews-filter.component.html',
  styleUrls: ['./new-reviews-filter.component.scss'],
  animations: [animation.collapsedHeightState, animation.rotate],
  providers: [OtasService],
})
export class CoreLibNewReviewsFilterComponent implements OnInit {
  isOpen = false;
  reviews = [];
  otas: Ota[] = [];

  subscriptions = new Subscription();
  isOpenMobile = [
    { target: FILTERS_NAME.OTA, isOpen: false },
    { target: FILTERS_NAME.ANSWER, isOpen: false },
    { target: FILTERS_NAME.NPS, isOpen: false },
    { target: FILTERS_NAME.QUALIFICATION, isOpen: false },
    { target: FILTERS_NAME.REASON, isOpen: false },
    { target: FILTERS_NAME.LANGUAGE, isOpen: false },
  ];
  firstTimeFromIRONPS = true;
  firstTimeFromIROAnswer = true;
  @Select(OnlineReviewsState.reviews) reviews$: Observable<any>;

  @HostListener('document:click', ['$event'])
  onClick(event) {
    const result = this.checkClickInside(event.target);
    if (!result) {
      this.isOpen = false;
    }
  }

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private otasService: OtasService,
    private utilService: UtilService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.setMenuMobile();
    this.handleOtaOptions();
    this.checkFilterFromQueryParams();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkClickInside(target) {
    const idsToVerify = ['overlayFiltersOnlineReviews', 'filtersReviews'];
    const classesToVerify = ['chip-no-close', 'generic-option', 'checkbox-option'];
    if (idsToVerify.includes(target.id) || classesToVerify.some((clas) => target.className?.includes(clas))) {
      return true;
    } else if (target.parentNode) {
      return this.checkClickInside(target.parentNode);
    } else {
      return false;
    }
  }

  setMenuMobile() {
    if (window.innerWidth >= 765) {
      this.isOpenMobile = this.isOpenMobile.map((filter) => {
        return {
          ...filter,
          isOpen: true,
        };
      });
    }
  }

  toggleMobileFilter(target) {
    if (this.isMobileMenu) {
      const index = this.isOpenMobile.findIndex((filter) => filter.target === target);
      this.isOpenMobile[index].isOpen = !this.isOpenMobile[index].isOpen;
    }
  }

  checkFilterFromQueryParams() {
    if (this.firstTimeFromIROAnswer || this.firstTimeFromIRONPS) {
      this.firstTimeFromIROAnswer = false;
      this.firstTimeFromIRONPS = false;

      const answerFromIRO = this.route.snapshot.queryParams?.answer;
      const npsFromIRO = this.route.snapshot.queryParams?.nps;

      const answerFilters = {
        [ANSWER_VALUES.RESPONDIDO.id]: ANSWER_VALUES.RESPONDIDO,
        [ANSWER_VALUES.NO_RESPONDIDO.id]: ANSWER_VALUES.NO_RESPONDIDO,
        [ANSWER_VALUES.NO_ADMITE_RESPUESTAS.id]: ANSWER_VALUES.NO_ADMITE_RESPUESTAS,
        [ANSWER_VALUES.EN_ESPERA_DE_APROBACION.id]: ANSWER_VALUES.EN_ESPERA_DE_APROBACION,
      };
      const npsFilters = {
        [NPS_VALUES.PROMOTER.text]: NPS_VALUES.PROMOTER,
        [NPS_VALUES.PASSIVE.text]: NPS_VALUES.PASSIVE,
        [NPS_VALUES.DETRACTOR.text]: NPS_VALUES.DETRACTOR,
      };

      [answerFilters[answerFromIRO], npsFilters[npsFromIRO]].forEach((filter) => this.setReviewFilterState(filter));
    }
  }

  setReviewFilterState(optionSelected = null) {
    if (Array.isArray(optionSelected)) {
      optionSelected.forEach((reason) => {
        reason.checked = true;
      });
    } else {
      optionSelected = { ...optionSelected, selected: true };
    }
    this.store.dispatch(new UpdateReviewsFilterForApi(optionSelected));
    this.updateOtaOptions();
  }

  clearFilters() {
    this.store.dispatch(new ClearFilterForApi());
    this.updateOtaOptions();
  }

  async getOtaOptions() {
    try {
      const res = await this.otasService.getOtasByConfigClient(this.currentCustomer.id).toPromise();
      this.otas = res.filter((ota) => ota.active && ota.get_reviews && ota.id !== 5); // Excluding hotels.com
      this.otas.forEach((ota: Ota) => (ota.type = 'ota'));
    } catch (error) {
      console.error(error);
    }
  }

  updateOtaOptions() {
    const filtersForApi = this.store.selectSnapshot(OnlineReviewsState.filterForApi);
    if (Object.keys(filtersForApi).length === 0) {
      // If there are no filters, all otas are not selected
      this.otas.forEach((ota: Ota) => (ota.selected = false));
      return;
    }
    this.otas.forEach((ota: Ota) => {
      if (filtersForApi && filtersForApi.length > 0) {
        const optionSelected = filtersForApi.find((filter) => filter.id === ota.id && filter.name === ota.name);
        ota.selected = optionSelected ? true : false;
      }
    });
  }

  async handleOtaOptions() {
    await this.getOtaOptions();
    this.checkOtaQueryParams();
  }

  checkOtaQueryParams() {
    const otaFromQueryParam = this.route.snapshot.queryParams?.ota_id;
    if (!otaFromQueryParam) return;
    const otaSelected = this.otas.find((ota) => ota.id === Number(otaFromQueryParam));
    this.setReviewFilterState(otaSelected);
  }

  filterByType(filtersByType, type) {
    const filterOptions = filtersByType.options.map((option) => {
      const optionSelected = this.filtersForApi.find(
        (filter) => filter.id === option.id && filter[type] === option[type],
      );
      if (optionSelected) {
        return {
          ...option,
          selected: true,
        };
      } else {
        return {
          ...option,
          selected: false,
        };
      }
    });
    return filterOptions;
  }

  get answerOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const answerFilter = filters.find((filter) => filter.name === FILTERS_NAME.ANSWER);
    if (answerFilter) {
      if (this.filtersForApi && this.filtersForApi.length > 0) {
        return this.filterByType(answerFilter, 'text');
      }
      return answerFilter.options;
    }
    return [];
  }

  get npsOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const npsFilter = filters.find((filter) => filter.name === FILTERS_NAME.NPS);
    if (npsFilter) {
      if (this.filtersForApi && this.filtersForApi.length > 0) {
        return this.filterByType(npsFilter, 'text');
      }
      return npsFilter.options;
    }
    return [];
  }

  get qualificationOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const qualificationFilter = filters.find((filter) => filter.name === FILTERS_NAME.QUALIFICATION);
    if (qualificationFilter) {
      if (this.filtersForApi && this.filtersForApi.length > 0) {
        return this.filterByType(qualificationFilter, 'value');
      }
      return qualificationFilter.options;
    }
    return [];
  }

  get reasonOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const reasonFilter = filters.find((filter) => filter.name === FILTERS_NAME.REASON);
    if (reasonFilter) {
      if (this.filtersForApi && this.filtersForApi.length > 0) {
        return this.filterByType(reasonFilter, 'name_en');
      }
      return reasonFilter.options;
    }
    return [];
  }

  get languageOptions() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filters);
    const languageFilter = filters.find((filter) => filter.name === FILTERS_NAME.LANGUAGE);
    if (languageFilter) {
      if (this.filtersForApi && this.filtersForApi.length > 0) {
        return this.filterByType(languageFilter, 'code');
      }
      return languageFilter.options;
    }
    return [];
  }

  get displayOTAOptions() {
    return (option: any) => {
      return option.name;
    };
  }

  get displayAnswerOptions() {
    enum VALUES {
      ANSWERED = 1,
      NOT_ANSWERED = 2,
      NOT_ALLOWED = 3,
      WAITING = 4,
    }
    return (option: any) => {
      return option.value === VALUES.ANSWERED
        ? this.translateService.instant('commons.answered_reviews')
        : option.value === VALUES.NOT_ANSWERED
        ? this.translateService.instant('commons.not_answered_reviews')
        : option.value === VALUES.WAITING
        ? this.translateService.instant('commons.awaiting_approval')
        : this.translateService.instant('commons.answer_not_allowed');
    };
  }

  get displayNpsOptions() {
    enum VALUES {
      PROMOTERS = 'promoters',
      PROMOTER = 'promoter',
      PASSIVES = 'passives',
      PASSIVE = 'passive',
      DETRACTORS = 'detractors',
      DETRACTOR = 'detractor',
    }
    return (option: any) => {
      return option.text === VALUES.PROMOTER
        ? this.translateService.instant(`qualifys.${VALUES.PROMOTERS}`)
        : option.text === VALUES.PASSIVE
        ? this.translateService.instant(`qualifys.${VALUES.PASSIVES}`)
        : this.translateService.instant(`qualifys.${VALUES.DETRACTORS}`);
    };
  }

  get displayQualificationOptions() {
    return (option: any) => {
      return this.translateService.instant(`commons.${option.text}`);
    };
  }

  get displayChipsFn() {
    const currentLanguage = this.store.selectSnapshot(DefaultLanguageState.languageCode);
    return (option) => {
      if (currentLanguage === 'es') return option.name_es;
      if (currentLanguage === 'en') return option.name_en;
      return '';
    };
  }

  get displayLanguageFn() {
    const PREFIX_TRANSLATE = 'languages.id.';
    return (option: any) => {
      return this.translateService.instant(`${PREFIX_TRANSLATE}${option.id || 0}`);
    };
  }

  get otaFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.OTA).isOpen;
  }

  get answerFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.ANSWER).isOpen;
  }

  get npsFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.NPS).isOpen;
  }

  get qualificationFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.QUALIFICATION).isOpen;
  }

  get reasonFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.REASON).isOpen;
  }

  get languageFilterStatus() {
    return this.isOpenMobile.find((filter) => filter.target === FILTERS_NAME.LANGUAGE).isOpen;
  }

  get areFiltersApplied() {
    const filters = this.store.selectSnapshot(OnlineReviewsState.filterForApi);
    if (filters) return filters.length > 0;
  }

  get isMobileMenu() {
    return window.innerWidth < 765;
  }

  get currentCustomer() {
    return this.utilService.getCurrentCustomer();
  }

  get filtersForApi() {
    return this.store.selectSnapshot(OnlineReviewsState.filterForApi);
  }
}
