import { UsersActions } from './users-actions.state';

export class StoreUsersActions {
  static readonly type = '[Users Actions] Store Users Actions';
  constructor(public actions: UsersActions[]) {}
}

export class StoreUserRole {
  static readonly type = '[Users Actions] Store User Role';
  constructor(public role: string) {}
}
