import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CasesPropertiesService {

  constructor(
    private http: HttpClient
  ) { }

  getDeskCorpByStatus(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/status?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getDeskCorpByPriority(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/priority?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getDeskCorpByDistribution(customerIds, dates) {
    const url = `${environment.casesPath}corporative/cases/distribution?customers_ids=${customerIds}&start_date=${dates.startDate}&end_date=${dates.endDate}`;
    return this.http.get(url);
  }

  getActiveHotels(customerIds: number[]): Promise<number[]> {
    const url = `${environment.casesPath}corporative/active`;
    const ids = customerIds.length ? customerIds.join(',') : null;
    let params = {}
    if (ids) {
      params = new HttpParams()
        .set('customers_ids', ids);
    }
    return this.http.get<number[]>(url, { params }).toPromise();
  }
}
