<div class="tw-flex tw-justify-between" *ngIf="progressRate">
  <div>
    <p class="tw-text-xl tw-font-semibold"[innerHTML]="'user_management.create_user.complete_your_profile' | translate"></p>
  </div>
  <div class="tw-flex tw-gap-1">
    <div class="tw-mb-5 tw-h-2 tw-rounded-full tw-bg-gray-200 tw-w-80 tw-mt-[10px]">
      <div class="tw-h-2 tw-rounded-full tw-bg-[#0F93CC] tw-w-[25%]"></div>
    </div>
    <p class="tw-text-xl">{{progressRate}}%</p>
  </div>
</div>

<form [formGroup]="form">
  <div class="tw-flex">
    <div class="tw-flex tw-w-1/2 tw-flex-wrap">
      <ng-container *ngFor="let controlName of controls">
        <div class="tw-flex tw-flex-col tw-flex-auto tw-w-1/3 tw-mx-3 xl:tw-mx-5 tw-py-3">
          <ng-container [ngSwitch]="controlName">
            <ng-container *ngSwitchCase="'language'">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let lang of languages" [ngValue]="lang.id">{{ 'languages.iso.' + lang.code | translate }}</option>
              </select>
            </ng-container>
  
            <ng-container *ngSwitchCase="controlName === 'department' || controlName === 'jobType' ? controlName : ''">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let item of (controlName === 'department' ? departments : (controlName === 'jobType') ? roles : rolesList)" [ngValue]="item.id">{{ 'models.user_profile.roles.' + item.id | translate }}</option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="'role'">
              <div class="tw-flex tw-justify-between">
                <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
                <label class="tw-text-[#0F93CC] tw-text-xs tw-underline tw-cursor-pointer" (click)="openRolesActionsModal()">{{ 'user_management.create_user.whats_this' | translate }}</label>
              </div>
              <select class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-1" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">
                <option *ngFor="let item of (controlName === 'department' ? departments : (controlName === 'jobType') ? roles : rolesList)" [ngValue]="item.id">{{ 'models.user_profile.roles.' + item.id | translate }}</option>
              </select>
            </ng-container>
  
            <ng-conttainer *ngSwitchCase="controlName === 'cellphone' || controlName === 'phone' ? controlName : ''">
              <label class="tw-text-main-text tw-text-xs">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <div class="tw-flex tw-flex-row">
                <select class="tw-w-2/6 tw-h-[34px] tw-mr-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-pt-2 tw-text-xs tw-font-bold	" [formControlName]="controlName === 'cellphone' ? 'cellphoneCountryCode' : 'phoneCountryCode'">
                  <option *ngFor="let code of phonesCodes" [ngValue]="'+' + code.code_phone">{{ '+' + code.code_phone }}</option>
                </select>
                <input class="tw-w-4/6 tw-h-[34px] tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" type="text" [formControlName]="controlName" (keypress)="numbersOnly($event)" />
              </div>
            </ng-conttainer>
  
            <ng-container *ngSwitchCase="'birthdate'">
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <input type="date" class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" lang="us-EN" onkeydown="return false" [formControlName]="controlName" placeholder="MM/DD/YYYY"/>
            </ng-container>
  
            <ng-container *ngSwitchCase="'hubspot_id'">
              <label class="tw-text-main-text tw-text-xs">{{ 'user_management.create_user.personal_info.' + controlName | translate }}</label>
              <input class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font tw-w-[47.5%]" type="text" [formControlName]="controlName"/>
            </ng-container>
            
            <ng-container *ngSwitchDefault>
              <label class="tw-text-main-text tw-text-xs" [ngClass]="{'tw-text-detractors' : !form.get(controlName).valid && form.get(controlName).touched }">{{ 'user_management.create_user.personal_info.' + controlName | translate }}<span class="tw-text-red-500">*</span></label>
              <input class="tw-h-[34px] tw-my-2 tw-rounded-md tw-border tw-border-border-light-gray tw-bg-fff disabled:tw-bg-main-disabled disabled:tw-text-disabled-font" type="text" [formControlName]="controlName" [ngClass]="{'tw-border-detractors' : !form.get(controlName).valid && form.get(controlName).touched }"/>
            </ng-container>
          </ng-container>
  
        </div>
      </ng-container>
    </div>
    <div class="tw-w-1/2 tw-self-center tw-flex tw-flex-col tw-justify-center tw-content-center tw-flex-wrap">
      <mh-profile-photo [image]="data?.profile_photo || null" (changed)="changeProfilePhoto($event)"></mh-profile-photo>
      <div class="tw-mt-12 tw-ml-2" *ngIf="personalAdjusts">
        <mh-core-lib-button [isDefault]="true" [isDisabled]="!form.valid" [text]="('forms.edit_password' | translate)" [width]="200" (click)="editPassword()"></mh-core-lib-button>
      </div>
    </div>
  </div>

  <div class="tw-flex tw-justify-center tw-mt-40 tw-gap-5" *ngIf="progressRate; else saveChanges">
    <mh-core-lib-button [isSecondary]="true" [isDisabled]="false" [text]="('commons.back' | translate)" (click)="updateIndexEmit(false)"></mh-core-lib-button>
    <mh-core-lib-button [isDefault]="true" [isDisabled]="!form.valid" [text]="('user_management.create_user.next' | translate)" (click)="updateIndexEmit(true)"></mh-core-lib-button>
  </div>
</form>

<ng-template #saveChanges>
  <div class="tw-flex tw-justify-center">
    <mh-core-lib-button [isDefault]="true" [isDisabled]="!form.valid" [text]="('commons.save_changes' | translate)" (click)="emitUser()"></mh-core-lib-button>
  </div>
</ng-template>
