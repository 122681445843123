<section id="hotel_form">
  <a class="btn clean" routerLink="/customer/configuracion/hotel/new" *ngIf="canEdit" >
    {{'customer.configurations.hotel.add_new' | translate}}
  </a>
  <div class="align_center">
    <mh-spinner-classic [hidden]="!waiting"></mh-spinner-classic>
  </div>
  <form class="general_set_form show_invalids" (ngSubmit)="saveData()" [formGroup]="hotelForm" #formDir="ngForm"  *ngIf="!waiting">
    <section id="hotel_information" class="general_box">
      <h3 class="title">{{'customer.configurations.hotel.form.information_title' | translate }}</h3>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-4 logo_side">
              <mh-core-lib-actions-wrapper
                  slug="company_settings_edit"
                >
                <mh-customer-hotel-images-form
                    [parentForm]="hotelForm"
                    [hotelDto]="hotelDto"
                    [hotelBase] = "hotel_base"
                    [viewType]="viewType"
                    [disabled]="!canEdit"
                    (waiting)="setUpdateElements($event)"
                    (isReady)="readyFormPart($event)">
                </mh-customer-hotel-images-form>
              </mh-core-lib-actions-wrapper>
            </div>
            <div class="col-8">
              <mh-core-lib-actions-wrapper
                  slug="company_settings_edit"
                >
              <div class="row">
                <ng-container *ngFor="let key of hotel_information">
                  <ng-container [ngSwitch]="key">
                    <ng-template [ngSwitchCase]="'web_site'">
                      <div class="col-12 tw-mb-3 tw-group" >
                        <input type="text" id="{{key}}" class="form-control group-hover:tw-cursor-pointer group-hover:tw-text-main-link tw-duration-150 tw-transition-colors" readonly  [value]="hotelDto?.web_site" (click)="goToWebsite()">
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="(['country'].includes(key) ? key : false )">
                      <div class="col-6">
                        <widget-list-options
                          [label]= "'country'"
                          [name]="'hotel-form-select-'+key"
                          [list]="listOptions['countries']"
                          [selected]="hotelForm.controls[key].value.code2"
                          [keyValue] = "'code2'"
                          [keyName] = "'code2'"
                          [prefixTranslate]="'countries'"
                          [disabled]="!isCompetitor"
                          [tooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"
                          (select)="setSelectlValue($event,key)">
                        </widget-list-options>
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="(['currency'].includes(key) ? key : false )">
                      <div class="col-6">
                        <widget-list-options
                          [label]= "('models.hotel_dto.'+key | translate)"
                          [name]="'hotel-form-select-'+key"
                          [list]="listOptions[key]"
                          [selected]="hotelForm.controls[key].value.id"
                          [keyValue] = "'id'"
                          [keyName] = "'code'"
                          [disabled]="!canEdit"
                          (select)="setSelectlValue($event,key)">
                        </widget-list-options>
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="(['language'].includes(key) ? key : false )">
                      <div class="col-6">
                        <widget-list-options
                          [label]= "'default_sent'"
                          [name]="'hotel-form-select-'+key"
                          [list]="listOptions[key]"
                          [selected]="hotelForm.controls[key].value.id"
                          [keyValue] = "'id'"
                          [keyName] = "'code'"
                          [prefixTranslate] = "'languages.iso'"
                          [disabled]="!canEdit"
                          (select)="setSelectlValue($event,key)">
                        </widget-list-options>
                      </div>
                    </ng-template>


                    <ng-template [ngSwitchCase]="(['city'].includes(key) ? key : false )">
                      <div class="col-6">
                        <div class="inline">
                          <widget-list-options
                            [label]= "('models.hotel_dto.'+key | translate)"
                            [name]="'hotel-form-select-'+key"
                            [list]="listOptions['cities']"
                            [selected]="hotelForm.controls[key].value?.id"
                            [keyValue] = "'id'"
                            [keyName] = "'name'"
                            [disabled]="!isCompetitor"
                            [tooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"
                            (select)="setSelectlValue($event,key)">
                          </widget-list-options>
                        </div>
                        <div class="inline" [class.invisible]="!changeCity">
                          <mh-spinner-classic></mh-spinner-classic>
                        </div>
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="(['automatic_utc'].includes(key) ? key : false )">
                      <div class="col-6 wrapp_form">
                        <label for="{{key}}">{{'models.hotel_dto.'+key | translate}}</label>
                        <mat-slide-toggle id="{{key}}" class="form-switch"  formControlName="{{key}}" [checked]="hotelForm.controls[key].value" [id]="key" >
                          {{'commons.'+(hotelForm.controls[key].value ? 'yes' : 'no') | translate}}
                        </mat-slide-toggle>
                      </div>
                    </ng-template>

                    <ng-template [ngSwitchCase]="(['utc'].includes(key) ? key : false )">
                      <div class="col-6" [hidden]="hotelForm.controls.automatic_utc.value">
                        <widget-list-options
                          [label]= "('models.hotel_dto.'+key | translate)"
                          [name]="'hotel-form-select-'+key"
                          [list]="listOptions[key]"
                          [selected]="hotelForm.controls[key].value"
                          [disabled]="!canEdit"
                          [keyValue] = "'utc_miliseconds'"
                          [keyName] = "'utc_format'"
                          (select)="setSelectlValue($event,key)">
                        </widget-list-options>
                      </div>
                    </ng-template>



                    <ng-template ngSwitchDefault>
                      <div class="col-12 wrapp_form">
                        <div *ngIf="'name'.includes(key) && isSuperAdmin">
                          <span>
                            ID {{'commons.client' | translate}} <strong>{{hotel.id}}</strong>
                          </span>
                        </div>
                        <label for="{{key}}">
                          {{'models.hotel_dto.'+key | translate}}
                          <i *ngIf="defaultDisabledFields.includes(key)" class="fas fa-question-circle" [ngbTooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"></i>
                        </label>
                        <input type="text" id="{{key}}" class="form-control"  formControlName="{{key}}"  [attr.disabled]=" isCompetitor && defaultDisabledFields.includes(key) ? null : '' ">
                        <ng-container *ngTemplateOutlet="formAlerts;context:{element: hotelForm.controls[key]}"></ng-container>
                      </div>
                    </ng-template>
                  </ng-container>

                </ng-container>
              </div>
              </mh-core-lib-actions-wrapper>

              <div class="row tw-mt-20">

                <div class="col-3">
                <mh-core-lib-actions-wrapper
                    slug="company_settings_api_key_view"
                  >
                  <div class="apikey" [hidden]="showInputApiKey">
                    <button class="tw-border-main-orange tw-rounded-full tw-border tw-px-5 tw-py-2.5 tw-text-white tw-font-medium tw-bg-main-orange hover:tw-bg-main-orange-hover tw-transition-all" type="button" (click)="showApiKey()" container="body"
                    [ngbTooltip]="('customer.configurations.hotel.tooltips.access_key' | translate)"> {{'commons.see' | translate}} Access-Key</button>
                  </div>
                  <mat-form-field class="apikey" [hidden]="!showInputApiKey">
                    <label>Access-Key</label>
                    <input matInput value={{apiKey}} readonly="true" container="body"
                    [ngbTooltip]="('customer.configurations.hotel.tooltips.access_key' | translate)">
                  </mat-form-field>
                </mh-core-lib-actions-wrapper>
                </div>

                <div class="col-9">
                <mh-core-lib-actions-wrapper
                    slug="company_settings_api_documentation_view"
                  >
                  <div class="apikey">
                    <button type="button" class="tw-border-main-orange tw-rounded-full tw-border tw-px-5 tw-py-2.5 tw-text-white tw-font-medium tw-bg-main-orange hover:tw-bg-main-orange-hover tw-transition-all" container="body" (click)="goToDoc()">
                      {{'commons.doc_api' | translate}}
                    </button>
                  </div>
                </mh-core-lib-actions-wrapper>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </section>

    <mh-core-lib-actions-wrapper
        slug="company_settings_edit"
      >
    <section id="hotel_properties" class="general_box" *ngIf="isSuperAdmin" >
      <h3 class="title">{{'customer.configurations.hotel.form.properties_titlte' | translate }}</h3>
      <div class="row">
        <div [class]="'col-' + getColClass(key) + ' wrapp_form'" *ngFor="let key of hotel_properties">
          <ng-container [ngSwitch]="key">
            <ng-template [ngSwitchCase]="(['default_checkin','default_checkout'].includes(key) ? key : false )">
              <widget-list-options
                [label]= "('models.hotel_dto.'+key | translate)"
                [name]="'hotel-form-select-'+key"
                [list]="(['default_checkin','default_checkout'].includes(key) ? listOptions['hours'] : listOptions[key])"
                [selected]="hotelForm.controls[key].value"
                [disabled]="!canEdit"
                (select)="setSelectlValue($event,key)">
              </widget-list-options>
            </ng-template>



            <ng-template [ngSwitchCase]="(['tramo','licence_type', 'subscription'].includes(key) ? key : false )">
              <widget-list-options
                [label]= "('models.hotel_dto.'+key | translate)"
                [name]="'hotel-form-select-'+key"
                [list]="listOptions[key]"
                [selected]="hotelForm.controls[key].value"
                [disabled]="!isCompetitor"
                [tooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"
                (select)="setSelectlValue($event,key)">
              </widget-list-options>

            </ng-template>

            <ng-template [ngSwitchCase]="(['stage'].includes(key) ? key : false )">
              <div *ngIf="isSuperAdmin">
                <widget-list-options
                    [label]="('models.hotel_dto.'+key | translate)"
                    [name]="'hotel-form-select-'+key"
                    [list]="listOptions[key]"
                    [selected]="hotelForm.controls[key]?.value?.id"
                    [keyValue]="'id'"
                    [keyName]="'name'"
                    [disabled]="!canEdit"
                    (select)="setSelectlValue($event,key)">
                </widget-list-options>
            </div>
            </ng-template>

            <ng-template [ngSwitchCase]="(['property_type'].includes(key) ? key : false )">
              <widget-list-options
                [name]="'testing'"
                [label]= "'label'"
                [list]="listOptions[key]"
                [selected]="hotelForm.controls[key].value.id"
                [disabled]="!canEdit"
                [keyName]="'id'"
                [keyValue]="'id'"
                [prefixTranslate]="'models.hotel_dto.'+key"
                (select)="setSelectlValue($event,key)">
              </widget-list-options>
            </ng-template>

            <ng-template [ngSwitchCase]="(['seasonal'].includes(key) ? key : false )">

              <label for="{{key}}">{{'models.hotel_dto.'+key | translate}}</label>
              <mat-slide-toggle [id]="(hotel.id+'_'+key)" class="form-switch"  formControlName="{{key}}" [checked]="hotelForm.controls[key].value" [id]="key" >
                {{'commons.'+(hotelForm.controls[key].value ? 'yes' : 'no') | translate}}
              </mat-slide-toggle>

            </ng-template>

            <ng-template [ngSwitchCase]="(['rooms_quantity','pipedrive_id', 'id_xero', 'hubspot_id'].includes(key) ? key : false )">
              <ng-container *ngIf="key === 'pipedrive_id'">
                <button type="button" (click)="handlePipedriveButton()" class="btn btn-orange pipedrive-btn" [disabled]="isPipedriveDisabled">
                  <span>
                    {{'customer.configurations.hotel.see_in_pipedrive' | translate}}
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="key === 'hubspot_id'">
                <button type="button" (click)="handleHubspotButton()" class="btn btn-orange pipedrive-btn" [disabled]="ishHubspotDisabled">
                  <span>
                    {{'customer.configurations.hotel.see_in_hubspot' | translate}}
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="key === 'id_xero'">
                <button type="button" (click)="handleXeroButton()" class="btn btn-orange pipedrive-btn" [disabled]="isXeroDisabled">
                  <span>
                    {{'customer.configurations.hotel.see_in_xero' | translate}}
                  </span>
                </button>
              </ng-container>
            </ng-template>
            <ng-template [ngSwitchCase]="(['flag_show_translations'].includes(key) ? key : false )">
              <label for="{{key}}">{{'models.hotel_dto.'+key | translate}} <i class="fas fa-question-circle" [ngbTooltip]="('configuration.tooltips.review_translates' | translate)"></i></label>
              <mat-slide-toggle [id]="(hotel.id+'_'+key)" class="form-switch"  formControlName="flag_show_translations" [checked]="hotelForm.controls[key].value" [id]="key">
                {{'commons.'+(hotelForm.controls[key].value ? 'yes' : 'no') | translate}}
              </mat-slide-toggle>
            </ng-template>

            <ng-template [ngSwitchCase]="(['commercial_executive', 'cs_executives'].includes(key) ? key : false)">
              <widget-list-options
                [name]="key"
                [list]="listOptions[key]"
                [selected]="hotelForm.controls[key].value"
                [keyName]="'name'"
                [keyValue]="'id'"
                [label]="('models.hotel_dto.'+key | translate)"
                [disabled]="!isCompetitor"
                [tooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"
                (select)="setSelectlValue($event, key)"
                ></widget-list-options>
            </ng-template>

            <ng-template ngSwitchDefault>
              <div class="row no-gutters input-no-padding">
                <mat-form-field appearance="standard">
                  <label for="{{key}}">
                    {{'models.hotel_dto.'+key | translate}}
                    <i *ngIf="defaultDisabledFields.includes(key)" class="fas fa-question-circle" [ngbTooltip]="('customer.configurations.hotel.form.crm_tooltip' | translate)"></i>
                  </label>
                  <input 
                    matInput
                    type="text" 
                    id="{{key}}" 
                    formControlName="{{key}}" 
                    [ngClass]="{'!tw-bg-[#e9ecef]': !isCompetitor && defaultDisabledFields.includes(key)}" 
                    [attr.disabled]=" isCompetitor && defaultDisabledFields.includes(key) ? null : '' " 
                  >
                </mat-form-field>
              </div>
            </ng-template>


          </ng-container>

          <ng-container *ngTemplateOutlet="formAlerts;context:{element: hotelForm.controls[key]}"></ng-container>
        </div>
      </div>
    </section>
    </mh-core-lib-actions-wrapper>

    <mh-core-lib-actions-wrapper
        slug="company_settings_products_edit"
      >
    <section id="hotel_products" class="general_box" *ngIf="isSuperAdmin">
      <h3 class="title">{{'customer.configurations.hotel.form.products_titlte' | translate }}</h3>
      <div class="row">
        <div class="col-4 wrapp_form" *ngFor="let key of hotel_products">

          <label for="{{key}}">{{'models.hotel_dto.'+key | translate}}</label>
          <mat-slide-toggle [id]="(hotel.id+'_'+key)" class="form-switch"  formControlName="{{key}}" [checked]="hotelForm.controls[key].value" [id]="key" (change)="setProductsToActive(key)">
            {{'commons.'+(hotelForm.controls[key].value ? 'yes' : 'no') | translate}}
          </mat-slide-toggle>

        </div>
      </div>
    </section>
    </mh-core-lib-actions-wrapper>

    <mh-core-lib-actions-wrapper slug="company_settings_chain_edit">
      <section id="hotel_chain_property" class="general_box" *ngIf="isSuperAdmin">
        <h3 class="title">{{'customer.configurations.hotel.form.chain_title' | translate }}</h3>
        <mh-customer-hotel-chain-form
          [parentForm]="hotelForm"
          [hotelDto]="hotelDto"
          [hotelBase] = "hotel_base"
          [listOptions] = "listOptions"
          [canEdit]="canEdit"
          (waiting)="setUpdateElements($event)"
          (isReady)="readyFormPart($event)">
        </mh-customer-hotel-chain-form>
      </section>
    </mh-core-lib-actions-wrapper>

    <section id="hotel_otas" class="general_box" *ngIf="isSuperAdmin && canEdit && viewType==='edit'">
      <h3 class="title">{{'customer.configurations.hotel.others.title' | translate }}</h3>
      <mh-forms-dialog
        [title]="('customer.configurations.hotel.others.otas' | translate)"
        [actionText]="('customer.configurations.hotel.others.otas' | translate)"
        [showButtonYes]="false"
        [modalSize]="'lg'"
        [showButtonNo]="false">
        <admin-configuration-otas
          [hotel]="hotel"
        ></admin-configuration-otas>
      </mh-forms-dialog>
    </section>

    <div class="bottom" *ngIf="canEdit" >
      <button type="submit" [disabled]="hotelForm.invalid || waitingElements || waitingSave" class="btn btn-orange">{{'forms.save_changes' | translate}}</button>
      <mh-spinner-classic [hidden]="!waitingSave"></mh-spinner-classic>
    </div>

  </form>
</section>

<ng-template #formAlerts let-element="element">
  <ng-container *ngIf="element">
    <small class="alert-danger">
      <ng-container  *ngIf="element.invalid && (element.dirty || element.touched)" >
        <div *ngIf="element.errors.required">
          {{'forms.attributes.not_empty' | translate}}
        </div>
        <div *ngIf="element.errors.minlength">
          {{'forms.attributes.min_lenght' | translate:({length: element.errors.minlength.requiredLength})}}
        </div>

        <div *ngIf="element.errors.maxlength">
          {{'forms.attributes.max_lenght' | translate:({length: element.errors.maxlength.requiredLength})}}
        </div>
      </ng-container>
    </small>
  </ng-container>
</ng-template>

<ng-template #formTypes let-data="data">

</ng-template>








