export default [
  {
    "country": "Afghanistan",
    "code_phone": "93",
    "code2": "AF",
    "code3": "AFG"
  },
  {
    "country": "Albania",
    "code_phone": "355",
    "code2": "AL",
    "code3": "ALB"
  },
  {
    "country": "Algeria",
    "code_phone": "213",
    "code2": "DZ",
    "code3": "DZA"
  },
  {
    "country": "American Samoa",
    "code_phone": "1-684",
    "code2": "AS",
    "code3": "ASM"
  },
  {
    "country": "Andorra",
    "code_phone": "376",
    "code2": "AD",
    "code3": "AND"
  },
  {
    "country": "Angola",
    "code_phone": "244",
    "code2": "AO",
    "code3": "AGO"
  },
  {
    "country": "Anguilla",
    "code_phone": "1-264",
    "code2": "AI",
    "code3": "AIA"
  },
  {
    "country": "Antarctica",
    "code_phone": "672",
    "code2": "AQ",
    "code3": "ATA"
  },
  {
    "country": "Antigua and Barbuda",
    "code_phone": "1-268",
    "code2": "AG",
    "code3": "ATG"
  },
  {
    "country": "Argentina",
    "code_phone": "54",
    "code2": "AR",
    "code3": "ARG"
  },
  {
    "country": "Armenia",
    "code_phone": "374",
    "code2": "AM",
    "code3": "ARM"
  },
  {
    "country": "Aruba",
    "code_phone": "297",
    "code2": "AW",
    "code3": "ABW"
  },
  {
    "country": "Australia",
    "code_phone": "61",
    "code2": "AU",
    "code3": "AUS"
  },
  {
    "country": "Austria",
    "code_phone": "43",
    "code2": "AT",
    "code3": "AUT"
  },
  {
    "country": "Azerbaijan",
    "code_phone": "994",
    "code2": "AZ",
    "code3": "AZE"
  },
  {
    "country": "Bahamas",
    "code_phone": "1-242",
    "code2": "BS",
    "code3": "BHS"
  },
  {
    "country": "Bahrain",
    "code_phone": "973",
    "code2": "BH",
    "code3": "BHR"
  },
  {
    "country": "Bangladesh",
    "code_phone": "880",
    "code2": "BD",
    "code3": "BGD"
  },
  {
    "country": "Barbados",
    "code_phone": "1-246",
    "code2": "BB",
    "code3": "BRB"
  },
  {
    "country": "Belarus",
    "code_phone": "375",
    "code2": "BY",
    "code3": "BLR"
  },
  {
    "country": "Belgium",
    "code_phone": "32",
    "code2": "BE",
    "code3": "BEL"
  },
  {
    "country": "Belize",
    "code_phone": "501",
    "code2": "BZ",
    "code3": "BLZ"
  },
  {
    "country": "Benin",
    "code_phone": "229",
    "code2": "BJ",
    "code3": "BEN"
  },
  {
    "country": "Bermuda",
    "code_phone": "1-441",
    "code2": "BM",
    "code3": "BMU"
  },
  {
    "country": "Bhutan",
    "code_phone": "975",
    "code2": "BT",
    "code3": "BTN"
  },
  {
    "country": "Bolivia",
    "code_phone": "591",
    "code2": "BO",
    "code3": "BOL"
  },
  {
    "country": "Bosnia and Herzegovina",
    "code_phone": "387",
    "code2": "BA",
    "code3": "BIH"
  },
  {
    "country": "Botswana",
    "code_phone": "267",
    "code2": "BW",
    "code3": "BWA"
  },
  {
    "country": "Brazil",
    "code_phone": "55",
    "code2": "BR",
    "code3": "BRA"
  },
  {
    "country": "British Indian Ocean Territory",
    "code_phone": "246",
    "code2": "IO",
    "code3": "IOT"
  },
  {
    "country": "British Virgin Islands",
    "code_phone": "1-284",
    "code2": "VG",
    "code3": "VGB"
  },
  {
    "country": "Brunei",
    "code_phone": "673",
    "code2": "BN",
    "code3": "BRN"
  },
  {
    "country": "Bulgaria",
    "code_phone": "359",
    "code2": "BG",
    "code3": "BGR"
  },
  {
    "country": "Burkina Faso",
    "code_phone": "226",
    "code2": "BF",
    "code3": "BFA"
  },
  {
    "country": "Myanmar",
    "code_phone": "95",
    "code2": "MM",
    "code3": "MMR"
  },
  {
    "country": "Burundi",
    "code_phone": "257",
    "code2": "BI",
    "code3": "BDI"
  },
  {
    "country": "Cambodia",
    "code_phone": "855",
    "code2": "KH",
    "code3": "KHM"
  },
  {
    "country": "Cameroon",
    "code_phone": "237",
    "code2": "CM",
    "code3": "CMR"
  },
  {
    "country": "Canada",
    "code_phone": "1",
    "code2": "CA",
    "code3": "CAN"
  },
  {
    "country": "Cape Verde",
    "code_phone": "238",
    "code2": "CV",
    "code3": "CPV"
  },
  {
    "country": "Cayman Islands",
    "code_phone": "1-345",
    "code2": "KY",
    "code3": "CYM"
  },
  {
    "country": "Central African Republic",
    "code_phone": "236",
    "code2": "CF",
    "code3": "CAF"
  },
  {
    "country": "Chad",
    "code_phone": "235",
    "code2": "TD",
    "code3": "TCD"
  },
  {
    "country": "Chile",
    "code_phone": "56",
    "code2": "CL",
    "code3": "CHL"
  },
  {
    "country": "China",
    "code_phone": "86",
    "code2": "CN",
    "code3": "CHN"
  },
  {
    "country": "Christmas Island",
    "code_phone": "61",
    "code2": "CX",
    "code3": "CXR"
  },
  {
    "country": "Cocos Islands",
    "code_phone": "61",
    "code2": "CC",
    "code3": "CCK"
  },
  {
    "country": "Colombia",
    "code_phone": "57",
    "code2": "CO",
    "code3": "COL"
  },
  {
    "country": "Comoros",
    "code_phone": "269",
    "code2": "KM",
    "code3": "COM"
  },
  {
    "country": "Republic of the Congo",
    "code_phone": "242",
    "code2": "CG",
    "code3": "COG"
  },
  {
    "country": "Democratic Republic of the Congo",
    "code_phone": "243",
    "code2": "CD",
    "code3": "COD"
  },
  {
    "country": "Cook Islands",
    "code_phone": "682",
    "code2": "CK",
    "code3": "COK"
  },
  {
    "country": "Costa Rica",
    "code_phone": "506",
    "code2": "CR",
    "code3": "CRI"
  },
  {
    "country": "Croatia",
    "code_phone": "385",
    "code2": "HR",
    "code3": "HRV"
  },
  {
    "country": "Cuba",
    "code_phone": "53",
    "code2": "CU",
    "code3": "CUB"
  },
  {
    "country": "Curacao",
    "code_phone": "599",
    "code2": "CW",
    "code3": "CUW"
  },
  {
    "country": "Cyprus",
    "code_phone": "357",
    "code2": "CY",
    "code3": "CYP"
  },
  {
    "country": "Czech Republic",
    "code_phone": "420",
    "code2": "CZ",
    "code3": "CZE"
  },
  {
    "country": "Denmark",
    "code_phone": "45",
    "code2": "DK",
    "code3": "DNK"
  },
  {
    "country": "Djibouti",
    "code_phone": "253",
    "code2": "DJ",
    "code3": "DJI"
  },
  {
    "country": "Dominica",
    "code_phone": "1-767",
    "code2": "DM",
    "code3": "DMA"
  },
  {
    "country": "Dominican Republic",
    "code_phone": "1-809, 1-829, 1-849",
    "code2": "DO",
    "code3": "DOM"
  },
  {
    "country": "East Timor",
    "code_phone": "670",
    "code2": "TL",
    "code3": "TLS"
  },
  {
    "country": "Ecuador",
    "code_phone": "593",
    "code2": "EC",
    "code3": "ECU"
  },
  {
    "country": "Egypt",
    "code_phone": "20",
    "code2": "EG",
    "code3": "EGY"
  },
  {
    "country": "El Salvador",
    "code_phone": "503",
    "code2": "SV",
    "code3": "SLV"
  },
  {
    "country": "Equatorial Guinea",
    "code_phone": "240",
    "code2": "GQ",
    "code3": "GNQ"
  },
  {
    "country": "Eritrea",
    "code_phone": "291",
    "code2": "ER",
    "code3": "ERI"
  },
  {
    "country": "Estonia",
    "code_phone": "372",
    "code2": "EE",
    "code3": "EST"
  },
  {
    "country": "Ethiopia",
    "code_phone": "251",
    "code2": "ET",
    "code3": "ETH"
  },
  {
    "country": "Falkland Islands",
    "code_phone": "500",
    "code2": "FK",
    "code3": "FLK"
  },
  {
    "country": "Faroe Islands",
    "code_phone": "298",
    "code2": "FO",
    "code3": "FRO"
  },
  {
    "country": "Fiji",
    "code_phone": "679",
    "code2": "FJ",
    "code3": "FJI"
  },
  {
    "country": "Finland",
    "code_phone": "358",
    "code2": "FI",
    "code3": "FIN"
  },
  {
    "country": "France",
    "code_phone": "33",
    "code2": "FR",
    "code3": "FRA"
  },
  {
    "country": "French Polynesia",
    "code_phone": "689",
    "code2": "PF",
    "code3": "PYF"
  },
  {
    "country": "Gabon",
    "code_phone": "241",
    "code2": "GA",
    "code3": "GAB"
  },
  {
    "country": "Gambia",
    "code_phone": "220",
    "code2": "GM",
    "code3": "GMB"
  },
  {
    "country": "Georgia",
    "code_phone": "995",
    "code2": "GE",
    "code3": "GEO"
  },
  {
    "country": "Germany",
    "code_phone": "49",
    "code2": "DE",
    "code3": "DEU"
  },
  {
    "country": "Ghana",
    "code_phone": "233",
    "code2": "GH",
    "code3": "GHA"
  },
  {
    "country": "Gibraltar",
    "code_phone": "350",
    "code2": "GI",
    "code3": "GIB"
  },
  {
    "country": "Greece",
    "code_phone": "30",
    "code2": "GR",
    "code3": "GRC"
  },
  {
    "country": "Greenland",
    "code_phone": "299",
    "code2": "GL",
    "code3": "GRL"
  },
  {
    "country": "Grenada",
    "code_phone": "1-473",
    "code2": "GD",
    "code3": "GRD"
  },
  {
    "country": "Guam",
    "code_phone": "1-671",
    "code2": "GU",
    "code3": "GUM"
  },
  {
    "country": "Guatemala",
    "code_phone": "502",
    "code2": "GT",
    "code3": "GTM"
  },
  {
    "country": "Guernsey",
    "code_phone": "44-1481",
    "code2": "GG",
    "code3": "GGY"
  },
  {
    "country": "Guinea",
    "code_phone": "224",
    "code2": "GN",
    "code3": "GIN"
  },
  {
    "country": "Guinea-Bissau",
    "code_phone": "245",
    "code2": "GW",
    "code3": "GNB"
  },
  {
    "country": "Guyana",
    "code_phone": "592",
    "code2": "GY",
    "code3": "GUY"
  },
  {
    "country": "Haiti",
    "code_phone": "509",
    "code2": "HT",
    "code3": "HTI"
  },
  {
    "country": "Honduras",
    "code_phone": "504",
    "code2": "HN",
    "code3": "HND"
  },
  {
    "country": "Hong Kong",
    "code_phone": "852",
    "code2": "HK",
    "code3": "HKG"
  },
  {
    "country": "Hungary",
    "code_phone": "36",
    "code2": "HU",
    "code3": "HUN"
  },
  {
    "country": "Iceland",
    "code_phone": "354",
    "code2": "IS",
    "code3": "ISL"
  },
  {
    "country": "India",
    "code_phone": "91",
    "code2": "IN",
    "code3": "IND"
  },
  {
    "country": "Indonesia",
    "code_phone": "62",
    "code2": "ID",
    "code3": "IDN"
  },
  {
    "country": "Iran",
    "code_phone": "98",
    "code2": "IR",
    "code3": "IRN"
  },
  {
    "country": "Iraq",
    "code_phone": "964",
    "code2": "IQ",
    "code3": "IRQ"
  },
  {
    "country": "Ireland",
    "code_phone": "353",
    "code2": "IE",
    "code3": "IRL"
  },
  {
    "country": "Isle of Man",
    "code_phone": "44-1624",
    "code2": "IM",
    "code3": "IMN"
  },
  {
    "country": "Israel",
    "code_phone": "972",
    "code2": "IL",
    "code3": "ISR"
  },
  {
    "country": "Italy",
    "code_phone": "39",
    "code2": "IT",
    "code3": "ITA"
  },
  {
    "country": "Ivory Coast",
    "code_phone": "225",
    "code2": "CI",
    "code3": "CIV"
  },
  {
    "country": "Jamaica",
    "code_phone": "1-876",
    "code2": "JM",
    "code3": "JAM"
  },
  {
    "country": "Japan",
    "code_phone": "81",
    "code2": "JP",
    "code3": "JPN"
  },
  {
    "country": "Jersey",
    "code_phone": "44-1534",
    "code2": "JE",
    "code3": "JEY"
  },
  {
    "country": "Jordan",
    "code_phone": "962",
    "code2": "JO",
    "code3": "JOR"
  },
  {
    "country": "Kazakhstan",
    "code_phone": "7",
    "code2": "KZ",
    "code3": "KAZ"
  },
  {
    "country": "Kenya",
    "code_phone": "254",
    "code2": "KE",
    "code3": "KEN"
  },
  {
    "country": "Kiribati",
    "code_phone": "686",
    "code2": "KI",
    "code3": "KIR"
  },
  {
    "country": "Kosovo",
    "code_phone": "383",
    "code2": "XK",
    "code3": "XKX"
  },
  {
    "country": "Kuwait",
    "code_phone": "965",
    "code2": "KW",
    "code3": "KWT"
  },
  {
    "country": "Kyrgyzstan",
    "code_phone": "996",
    "code2": "KG",
    "code3": "KGZ"
  },
  {
    "country": "Laos",
    "code_phone": "856",
    "code2": "LA",
    "code3": "LAO"
  },
  {
    "country": "Latvia",
    "code_phone": "371",
    "code2": "LV",
    "code3": "LVA"
  },
  {
    "country": "Lebanon",
    "code_phone": "961",
    "code2": "LB",
    "code3": "LBN"
  },
  {
    "country": "Lesotho",
    "code_phone": "266",
    "code2": "LS",
    "code3": "LSO"
  },
  {
    "country": "Liberia",
    "code_phone": "231",
    "code2": "LR",
    "code3": "LBR"
  },
  {
    "country": "Libya",
    "code_phone": "218",
    "code2": "LY",
    "code3": "LBY"
  },
  {
    "country": "Liechtenstein",
    "code_phone": "423",
    "code2": "LI",
    "code3": "LIE"
  },
  {
    "country": "Lithuania",
    "code_phone": "370",
    "code2": "LT",
    "code3": "LTU"
  },
  {
    "country": "Luxembourg",
    "code_phone": "352",
    "code2": "LU",
    "code3": "LUX"
  },
  {
    "country": "Macau",
    "code_phone": "853",
    "code2": "MO",
    "code3": "MAC"
  },
  {
    "country": "Macedonia",
    "code_phone": "389",
    "code2": "MK",
    "code3": "MKD"
  },
  {
    "country": "Madagascar",
    "code_phone": "261",
    "code2": "MG",
    "code3": "MDG"
  },
  {
    "country": "Malawi",
    "code_phone": "265",
    "code2": "MW",
    "code3": "MWI"
  },
  {
    "country": "Malaysia",
    "code_phone": "60",
    "code2": "MY",
    "code3": "MYS"
  },
  {
    "country": "Maldives",
    "code_phone": "960",
    "code2": "MV",
    "code3": "MDV"
  },
  {
    "country": "Mali",
    "code_phone": "223",
    "code2": "ML",
    "code3": "MLI"
  },
  {
    "country": "Malta",
    "code_phone": "356",
    "code2": "MT",
    "code3": "MLT"
  },
  {
    "country": "Marshall Islands",
    "code_phone": "692",
    "code2": "MH",
    "code3": "MHL"
  },
  {
    "country": "Mauritania",
    "code_phone": "222",
    "code2": "MR",
    "code3": "MRT"
  },
  {
    "country": "Mauritius",
    "code_phone": "230",
    "code2": "MU",
    "code3": "MUS"
  },
  {
    "country": "Mayotte",
    "code_phone": "262",
    "code2": "YT",
    "code3": "MYT"
  },
  {
    "country": "Mexico",
    "code_phone": "52",
    "code2": "MX",
    "code3": "MEX"
  },
  {
    "country": "Micronesia",
    "code_phone": "691",
    "code2": "FM",
    "code3": "FSM"
  },
  {
    "country": "Moldova",
    "code_phone": "373",
    "code2": "MD",
    "code3": "MDA"
  },
  {
    "country": "Monaco",
    "code_phone": "377",
    "code2": "MC",
    "code3": "MCO"
  },
  {
    "country": "Mongolia",
    "code_phone": "976",
    "code2": "MN",
    "code3": "MNG"
  },
  {
    "country": "Montenegro",
    "code_phone": "382",
    "code2": "ME",
    "code3": "MNE"
  },
  {
    "country": "Montserrat",
    "code_phone": "1-664",
    "code2": "MS",
    "code3": "MSR"
  },
  {
    "country": "Morocco",
    "code_phone": "212",
    "code2": "MA",
    "code3": "MAR"
  },
  {
    "country": "Mozambique",
    "code_phone": "258",
    "code2": "MZ",
    "code3": "MOZ"
  },
  {
    "country": "Namibia",
    "code_phone": "264",
    "code2": "NA",
    "code3": "NAM"
  },
  {
    "country": "Nauru",
    "code_phone": "674",
    "code2": "NR",
    "code3": "NRU"
  },
  {
    "country": "Nepal",
    "code_phone": "977",
    "code2": "NP",
    "code3": "NPL"
  },
  {
    "country": "Netherlands",
    "code_phone": "31",
    "code2": "NL",
    "code3": "NLD"
  },
  {
    "country": "Netherlands Antilles",
    "code_phone": "599",
    "code2": "AN",
    "code3": "ANT"
  },
  {
    "country": "New Caledonia",
    "code_phone": "687",
    "code2": "NC",
    "code3": "NCL"
  },
  {
    "country": "New Zealand",
    "code_phone": "64",
    "code2": "NZ",
    "code3": "NZL"
  },
  {
    "country": "Nicaragua",
    "code_phone": "505",
    "code2": "NI",
    "code3": "NIC"
  },
  {
    "country": "Niger",
    "code_phone": "227",
    "code2": "NE",
    "code3": "NER"
  },
  {
    "country": "Nigeria",
    "code_phone": "234",
    "code2": "NG",
    "code3": "NGA"
  },
  {
    "country": "Niue",
    "code_phone": "683",
    "code2": "NU",
    "code3": "NIU"
  },
  {
    "country": "Northern Mariana Islands",
    "code_phone": "1-670",
    "code2": "MP",
    "code3": "MNP"
  },
  {
    "country": "North Korea",
    "code_phone": "850",
    "code2": "KP",
    "code3": "PRK"
  },
  {
    "country": "Norway",
    "code_phone": "47",
    "code2": "NO",
    "code3": "NOR"
  },
  {
    "country": "Oman",
    "code_phone": "968",
    "code2": "OM",
    "code3": "OMN"
  },
  {
    "country": "Pakistan",
    "code_phone": "92",
    "code2": "PK",
    "code3": "PAK"
  },
  {
    "country": "Palau",
    "code_phone": "680",
    "code2": "PW",
    "code3": "PLW"
  },
  {
    "country": "Palestine",
    "code_phone": "970",
    "code2": "PS",
    "code3": "PSE"
  },
  {
    "country": "Panama",
    "code_phone": "507",
    "code2": "PA",
    "code3": "PAN"
  },
  {
    "country": "Papua New Guinea",
    "code_phone": "675",
    "code2": "PG",
    "code3": "PNG"
  },
  {
    "country": "Paraguay",
    "code_phone": "595",
    "code2": "PY",
    "code3": "PRY"
  },
  {
    "country": "Peru",
    "code_phone": "51",
    "code2": "PE",
    "code3": "PER"
  },
  {
    "country": "Philippines",
    "code_phone": "63",
    "code2": "PH",
    "code3": "PHL"
  },
  {
    "country": "Pitcairn",
    "code_phone": "64",
    "code2": "PN",
    "code3": "PCN"
  },
  {
    "country": "Poland",
    "code_phone": "48",
    "code2": "PL",
    "code3": "POL"
  },
  {
    "country": "Portugal",
    "code_phone": "351",
    "code2": "PT",
    "code3": "PRT"
  },
  {
    "country": "Puerto Rico",
    "code_phone": "1-787, 1-939",
    "code2": "PR",
    "code3": "PRI"
  },
  {
    "country": "Qatar",
    "code_phone": "974",
    "code2": "QA",
    "code3": "QAT"
  },
  {
    "country": "Reunion",
    "code_phone": "262",
    "code2": "RE",
    "code3": "REU"
  },
  {
    "country": "Romania",
    "code_phone": "40",
    "code2": "RO",
    "code3": "ROU"
  },
  {
    "country": "Russia",
    "code_phone": "7",
    "code2": "RU",
    "code3": "RUS"
  },
  {
    "country": "Rwanda",
    "code_phone": "250",
    "code2": "RW",
    "code3": "RWA"
  },
  {
    "country": "Saint Barthelemy",
    "code_phone": "590",
    "code2": "BL",
    "code3": "BLM"
  },
  {
    "country": "Samoa",
    "code_phone": "685",
    "code2": "WS",
    "code3": "WSM"
  },
  {
    "country": "San Marino",
    "code_phone": "378",
    "code2": "SM",
    "code3": "SMR"
  },
  {
    "country": "Sao Tome and Principe",
    "code_phone": "239",
    "code2": "ST",
    "code3": "STP"
  },
  {
    "country": "Saudi Arabia",
    "code_phone": "966",
    "code2": "SA",
    "code3": "SAU"
  },
  {
    "country": "Senegal",
    "code_phone": "221",
    "code2": "SN",
    "code3": "SEN"
  },
  {
    "country": "Serbia",
    "code_phone": "381",
    "code2": "RS",
    "code3": "SRB"
  },
  {
    "country": "Seychelles",
    "code_phone": "248",
    "code2": "SC",
    "code3": "SYC"
  },
  {
    "country": "Sierra Leone",
    "code_phone": "232",
    "code2": "SL",
    "code3": "SLE"
  },
  {
    "country": "Singapore",
    "code_phone": "65",
    "code2": "SG",
    "code3": "SGP"
  },
  {
    "country": "Sint Maarten",
    "code_phone": "1-721",
    "code2": "SX",
    "code3": "SXM"
  },
  {
    "country": "Slovakia",
    "code_phone": "421",
    "code2": "SK",
    "code3": "SVK"
  },
  {
    "country": "Slovenia",
    "code_phone": "386",
    "code2": "SI",
    "code3": "SVN"
  },
  {
    "country": "Solomon Islands",
    "code_phone": "677",
    "code2": "SB",
    "code3": "SLB"
  },
  {
    "country": "Somalia",
    "code_phone": "252",
    "code2": "SO",
    "code3": "SOM"
  },
  {
    "country": "South Africa",
    "code_phone": "27",
    "code2": "ZA",
    "code3": "ZAF"
  },
  {
    "country": "South Korea",
    "code_phone": "82",
    "code2": "KR",
    "code3": "KOR"
  },
  {
    "country": "South Sudan",
    "code_phone": "211",
    "code2": "SS",
    "code3": "SSD"
  },
  {
    "country": "Spain",
    "code_phone": "34",
    "code2": "ES",
    "code3": "ESP"
  },
  {
    "country": "Sri Lanka",
    "code_phone": "94",
    "code2": "LK",
    "code3": "LKA"
  },
  {
    "country": "Saint Helena",
    "code_phone": "290",
    "code2": "SH",
    "code3": "SHN"
  },
  {
    "country": "Saint Kitts and Nevis",
    "code_phone": "1-869",
    "code2": "KN",
    "code3": "KNA"
  },
  {
    "country": "Saint Lucia",
    "code_phone": "1-758",
    "code2": "LC",
    "code3": "LCA"
  },
  {
    "country": "Saint Martin",
    "code_phone": "590",
    "code2": "MF",
    "code3": "MAF"
  },
  {
    "country": "Saint Pierre and Miquelon",
    "code_phone": "508",
    "code2": "PM",
    "code3": "SPM"
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "code_phone": "1-784",
    "code2": "VC",
    "code3": "VCT"
  },
  {
    "country": "Sudan",
    "code_phone": "249",
    "code2": "SD",
    "code3": "SDN"
  },
  {
    "country": "Suriname",
    "code_phone": "597",
    "code2": "SR",
    "code3": "SUR"
  },
  {
    "country": "Svalbard and Jan Mayen",
    "code_phone": "47",
    "code2": "SJ",
    "code3": "SJM"
  },
  {
    "country": "Swaziland",
    "code_phone": "268",
    "code2": "SZ",
    "code3": "SWZ"
  },
  {
    "country": "Sweden",
    "code_phone": "46",
    "code2": "SE",
    "code3": "SWE"
  },
  {
    "country": "Switzerland",
    "code_phone": "41",
    "code2": "CH",
    "code3": "CHE"
  },
  {
    "country": "Syria",
    "code_phone": "963",
    "code2": "SY",
    "code3": "SYR"
  },
  {
    "country": "Taiwan",
    "code_phone": "886",
    "code2": "TW",
    "code3": "TWN"
  },
  {
    "country": "Tajikistan",
    "code_phone": "992",
    "code2": "TJ",
    "code3": "TJK"
  },
  {
    "country": "Tanzania",
    "code_phone": "255",
    "code2": "TZ",
    "code3": "TZA"
  },
  {
    "country": "Thailand",
    "code_phone": "66",
    "code2": "TH",
    "code3": "THA"
  },
  {
    "country": "Togo",
    "code_phone": "228",
    "code2": "TG",
    "code3": "TGO"
  },
  {
    "country": "Tokelau",
    "code_phone": "690",
    "code2": "TK",
    "code3": "TKL"
  },
  {
    "country": "Tonga",
    "code_phone": "676",
    "code2": "TO",
    "code3": "TON"
  },
  {
    "country": "Trinidad and Tobago",
    "code_phone": "1-868",
    "code2": "TT",
    "code3": "TTO"
  },
  {
    "country": "Tunisia",
    "code_phone": "216",
    "code2": "TN",
    "code3": "TUN"
  },
  {
    "country": "Turkey",
    "code_phone": "90",
    "code2": "TR",
    "code3": "TUR"
  },
  {
    "country": "Turkmenistan",
    "code_phone": "993",
    "code2": "TM",
    "code3": "TKM"
  },
  {
    "country": "Turks and Caicos Islands",
    "code_phone": "1-649",
    "code2": "TC",
    "code3": "TCA"
  },
  {
    "country": "Tuvalu",
    "code_phone": "688",
    "code2": "TV",
    "code3": "TUV"
  },
  {
    "country": "United Arab Emirates",
    "code_phone": "971",
    "code2": "AE",
    "code3": "ARE"
  },
  {
    "country": "Uganda",
    "code_phone": "256",
    "code2": "UG",
    "code3": "UGA"
  },
  {
    "country": "United Kingdom",
    "code_phone": "44",
    "code2": "GB",
    "code3": "GBR"
  },
  {
    "country": "Ukraine",
    "code_phone": "380",
    "code2": "UA",
    "code3": "UKR"
  },
  {
    "country": "Uruguay",
    "code_phone": "598",
    "code2": "UY",
    "code3": "URY"
  },
  {
    "country": "United States",
    "code_phone": "1",
    "code2": "US",
    "code3": "USA"
  },
  {
    "country": "Uzbekistan",
    "code_phone": "998",
    "code2": "UZ",
    "code3": "UZB"
  },
  {
    "country": "Vanuatu",
    "code_phone": "678",
    "code2": "VU",
    "code3": "VUT"
  },
  {
    "country": "Vatican",
    "code_phone": "379",
    "code2": "VA",
    "code3": "VAT"
  },
  {
    "country": "Venezuela",
    "code_phone": "58",
    "code2": "VE",
    "code3": "VEN"
  },
  {
    "country": "Vietnam",
    "code_phone": "84",
    "code2": "VN",
    "code3": "VNM"
  },
  {
    "country": "U.S. Virgin Islands",
    "code_phone": "1-340",
    "code2": "VI",
    "code3": "VIR"
  },
  {
    "country": "Wallis and Futuna",
    "code_phone": "681",
    "code2": "WF",
    "code3": "WLF"
  },
  {
    "country": "Western Sahara",
    "code_phone": "212",
    "code2": "EH",
    "code3": "ESH"
  },
  {
    "country": "Yemen",
    "code_phone": "967",
    "code2": "YE",
    "code3": "YEM"
  },
  {
    "country": "Zambia",
    "code_phone": "260",
    "code2": "ZM",
    "code3": "ZMB"
  },
  {
    "country": "Zimbabwe",
    "code_phone": "263",
    "code2": "ZW",
    "code3": "ZWE"
  }
]

