import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { ConfirmationAnswerSurveyDialogComponent } from 'app/core-lib/dialogs/confirmation-answer-survey-dialog/confirmation-answer-survey-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from 'app/shared/customer.model';
import { TranslateService } from '@ngx-translate/core';
import { SurveyAnswerService } from 'app/shared/surveys/survey-answer.service';
import { UtilService } from 'app/shared/util.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Clipboard } from '@angular/cdk/clipboard';
import { Info } from 'app/shared/model/guest-answer.model';
import { ContactChanelType } from 'app/cases/types';
import { SmartRepliesService } from 'app/new-fs/smart-replies/smart-replies.service';
import { Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';

enum ManualOptionsId {
  PHONE = 3,
  WHATSAPP = 4,
  CONTACT = 5,
}

@Component({
  selector: 'mh-core-lib-answer-survey',
  templateUrl: './core-lib-answer-survey.component.html',
  styleUrls: ['./core-lib-answer-survey.component.scss'],
})
export class CoreLibAnswerSurveyComponent implements OnInit {
  @Input() customerFrom: string;
  @Input() survey: Info;
  @Input() customer: Customer;
  @Input() details: any;
  @Input() limitError;

  private _smartReplyText: string;
  showLoader: boolean;
  answerId: any;
  surveyId: any;
  @Input() set smartReplyText(newValue: string) {
    if (newValue && this._smartReplyText != newValue) {
      this._smartReplyText = newValue;
      this.contactSurveyForm?.get('body').setValue(this._smartReplyText);
    }
  }
  get smartReplyText() {
    return this._smartReplyText;
  }

  private _subject: string;
  @Input() set subject(value: string) {
    this._subject = value;
    if (this.contactSurveyForm) {
      this.contactSurveyForm.get('subject').setValue(value);
    }
  }

  get subject() {
    return this._subject;
  }

  @Output() closeContactTemplate: EventEmitter<{ data: boolean; from: string; type: string }> = new EventEmitter();
  public contactSurveyForm: FormGroup;
  public disableContactSection = false;
  public manualChannelSelected: ManualChannel;
  public tableTypes = ['email', 'others'];
  public selectedTableType = 'others';
  public showCheckoutMessage: string;
  public diffDays: number;
  public loadingRegister = false;
  public texteareaElementRef: ElementRef;
  public selectedContactChanel: ContactChanelType;
  public SrMonthlyLimit: number
  readonly optionsAvailableSmartReply = [2, 4] // ids of email and whastapp    
  

  public manualChannelOptions: ManualChannel[] = [
    { id: 2, name: 'email' },
    { id: 3, name: 'phone' },
    { id: 4, name: 'whatsapp' },
    { id: 5, name: 'contact' },
    { id: 6, name: 'others' },
  ];

  readonly iconItems: IconItemBox[] = [
    { id: 2, title: 'Email', icon: 'fas fa-envelope' },
    { id: 3, title: 'Teléfono', icon: 'fas fa-phone' },
    { id: 4, title: 'WhatsApp', icon: 'fab fa-whatsapp' },
    { id: 5, title: 'Presencial', icon: 'fas fa-handshake' },
    { id: 6, title: 'Otro', icon: 'fas fa-ellipsis-h' }
  ];

  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private surveyAnswerService: SurveyAnswerService,
    private utilService: UtilService,
    private router: Router,
    private clipboard: Clipboard,
    private smartRepliesService: SmartRepliesService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.surveyId = this.details.survey.answered_survey.survey_id;
    this.answerId = this.details.survey.answered_survey.id;
    this.createForm();
    this.checkCheckout();
  }

  checkCheckout() {
    if (!this.survey?.guest?.checkout) return;

    const checkoutDate = new Date(this.survey?.guest?.checkout);
    const checkoutDateTime = DateTime.fromJSDate(checkoutDate);
    const today = DateTime.now();
    const diff = today.diff(checkoutDateTime, 'days').toObject();
    this.diffDays = diff.days;
    this.showCheckoutMessage =
      diff.days < 0
        ? this.translate.instant('answer_survey.contact_dialog.on_site_hotel')
        : this.translate.instant('answer_survey.contact_dialog.left_hotel', { days: diff.days.toFixed() });
  }

  createForm() {
    if (!this.survey?.guest?.phone) {
      const whatsappIndex = this.manualChannelOptions.findIndex((item) => item.id === 4);
      const whatsappOption = this.manualChannelOptions[whatsappIndex];
      whatsappOption.disabled = true

      const iconOption = this.iconItems.find(item =>  whatsappOption.id === item.id );
      iconOption.disabled = true
    };
    const defaultSubject = this.translate.instant('answer_survey.contact_dialog.subject', {
      hotelName: this.customer?.name,
    });
    this.contactSurveyForm = this.fb.group({
      description: [{ value: ''}],
      subject: [this.limitError ? null : this.subject || defaultSubject],
      body: [this.smartReplyText ? this.smartReplyText : ''],
    });
  }

  async handleManualOptionClicked(option: ManualChannel) {     
    if (option?.selected &&  (this.manualChannelSelected?.id === option?.id)) {
      this.disableContactSection = false;
      this.manualChannelSelected.selected = this.disableContactSection
      !this.disableContactSection ?  this.manualChannelSelected = null : this.manualChannelSelected = option;
      return;
    }

    this.manualChannelSelected = option;
    this.validateDescriptionValue();
    await this.checkSmartReply();
    // validate email case
    if( this.manualChannelSelected.name === 'email' )  await this.getMailingData(this.answerId)
  }

  async handleIconClicked(item) {
    if (item.disabled) return;
    this.manualChannelSelected = this.manualChannelOptions.find( option => option.id === item.id );
    this.manualChannelSelected ? this.manualChannelSelected.selected = true : null;
    await this.checkSmartReply();
    this.validateDescriptionValue();
    // validate email case
    if( this.manualChannelSelected.name === 'email' )  await this.getMailingData(this.answerId)
  }

  async checkSmartReply() {
    if (  this.optionsAvailableSmartReply.includes(this.manualChannelSelected?.id) && !this.smartReplyText && !this.limitError ) {
      this.showLoader = true;
      const customerState = this.store.selectSnapshot(CustomersState.currentCustomer);
      const resp = await this.smartRepliesService.getSmartReplyUsage(customerState.id).toPromise();
      this.limitError = resp.can_generate_more ? null : resp.monthly_limit;
      this.SrMonthlyLimit = resp.monthly_limit;
      this.showLoader = false;
      if (this.limitError) return;


      await this.getSmartReply(this.surveyId, this.answerId);
      this.manualChannelSelected?.name === 'whatsapp' ? this.contactSurveyForm?.get('description')?.setValue(this._smartReplyText) : this.contactSurveyForm?.get('body').setValue(this._smartReplyText)
    }
  }

  validateDescriptionValue() {
    this.manualChannelSelected?.name === 'whatsapp' ? this.contactSurveyForm?.get('description')?.setValue(this._smartReplyText) : this.contactSurveyForm?.get('description').setValue('')
  }

  async getSmartReply(surveyId: number, answerId: number) {
    this.showLoader = true;
    try {
      const resp = await this.surveyAnswerService.getSmartReplysurveyId(surveyId, answerId).toPromise();
      if (resp && resp.answer.length > 0) {
        this.smartReplyText = `${resp.answer}\n\n\n${resp.signature}`;
        this.showLoader = false;
      }
    } catch (error) {
      this.showLoader = false;
      if (error?.errors[0]?.messages[0]?.text === 'Usage limit exceeded') {
        this.limitError = this.SrMonthlyLimit || true;
      }
      console.error(error);
    }
  }

  async getMailingData(answerId: number) {
    if ( this.customerFrom && this.subject ) return; // if these values exists then doesnt make a new request
    this.showLoader = true
    try {
      const resp = await this.surveyAnswerService.getMailingData(this.details.customer.id, answerId).toPromise();
      if (resp) {
        this.customerFrom = `${resp.from_name} <${resp.from_mail}>`;
        this.subject = resp.subject;
        this.ngOnInit()
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.showLoader = false;
    }
  }

  async registerContact(type: string) {
    this.loadingRegister = true;
    const channelId = type === 'email' ? 2 : this.manualChannelSelected.id;
    const field = type === 'email' ? 'body' : 'description';
    const description = this.contactSurveyForm.get(field).value;
    const body = this.contactSurveyForm.get('body').value;
    const payload: ContactPayload = {
      description,
      body,
    };

    if (type === 'email') {
      payload.subject = this.contactSurveyForm.get('subject').value;
      payload.guest_id = this.survey?.guest?.id;
      payload.guest_email = this.survey?.guest?.email;
    }

    try {
      const resp = await this.surveyAnswerService
        .registerContactSurvey(this.survey?.answered_survey?.id, channelId, payload)
        .toPromise();
      this.loadingRegister = false;
      if (resp) this.closeAndUpdate(type);
    } catch (error) {
      this.loadingRegister = false;
      console.error(error);
    }
  }

  close() {
    this.closeContactTemplate.emit();
  }

  closeAndUpdate(type: string) {
    this.closeContactTemplate.emit({ data: true, from: 'answer-survey', type });
  }

  showConfirmDialog(type: string) {
    const user = this.utilService.getCurrentUser();
    const config = {
      data: {
        type,
        userEmail: user.email,
      },
    };
    const classes = ['overlay-panel', 'fit-height-content'];
    const backdropClasses = ['overlay-backdrop-inherit'];
    const dialogRef = this.modalService.open(ConfirmationAnswerSurveyDialogComponent, config, classes, backdropClasses);
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (type === 'email') {
          this.registerContact(type);
        } else {
          this.openWhatsappWindow();
        }
      }
    });
  }

  openWhatsappWindow() {
    const phone = this.survey?.guest?.phone;
    const text = this.contactSurveyForm.get('body').value;
    const url = `https://wa.me/${phone}?text=${text}`;
    window.open(url, '_blank');
  }

  copySmartReply() {
    this.clipboard.copy(this.smartReplyText);
  }

  focosOnTextarea(element: ElementRef) {
    this.texteareaElementRef = element;
    element.nativeElement.focus();
  }

  get product() {
    return this.router.url.includes('followup') ? 'followup' : 'onsite';
  }

  get registerContactSlug() {
    return this.router.url.includes('followup')
      ? 'followup_guests_manual_contact_register'
      : 'onsite_guests_manual_contact_register';
  }

  get contactEmailSlug() {
    return this.router.url.includes('followup')
      ? 'followup_guests_email_contact_register'
      : 'onsite_guests_email_contact_register';
  }

  get isEmailValid() {    
    return this.survey.guest.flag_email_valid;
  }
}

export interface ManualChannel {
  id: number;
  name: ContactChanelType;
  selected?: boolean;
  disabled?: boolean;
}

export interface IconItemBox {
  id: number;
  title: string;
  icon: string;
  disabled?: boolean;
}

export interface ContactPayload {
  guest_id?: number;
  guest_email?: string;
  subject?: string;
  description: string;
  body: string;
}
