import { State, Action, StateContext, Selector } from '@ngxs/store';
import { StoreUserRole, StoreUsersActions } from './users-actions.actions';

export type UsersActions = {
  active: boolean;
  comment: string;
  super_admin: boolean;
  id: number;
  type_id: number;
  product_id: number;
  slug: string;
  title: string;
};

interface UsersActionsModel {
  actions: UsersActions[];
  role: string;
}

@State<UsersActionsModel>({
  name: 'usersActions',
  defaults: {
    actions: [],
    role: '',
  },
})
export class UsersActionsState {
  @Selector()
  static role(state: UsersActionsModel) {
    return state.role;
  }

  @Selector()
  static getActions(state: UsersActionsModel) {
    return state.actions;
  }

  @Action(StoreUsersActions)
  storeUsersActions(context: StateContext<UsersActionsModel>, action: StoreUsersActions) {
    context.patchState({
      actions: action.actions,
    });
  }

  @Action(StoreUserRole)
  storeUserRole(context: StateContext<UsersActionsModel>, action: StoreUserRole) {
    context.patchState({
      role: action.role,
    });
  }
}
