import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddDataMenu, AddDataSidebar, AddDataTopbar, AddDataUsermenu, ResetMenuState } from './menus.actions';
import { MenuItem } from './menus.model';
export interface MenusModel {
  sidebar: MenuItem[];
  topbar: MenuItem[];
  usermenu: MenuItem[];
  configs: MenuItem[];
  managers: MenuItem[];
}

@State<MenusModel>({
  name: 'menus',
  defaults: {
    sidebar: [],
    topbar: [],
    usermenu: [],
    configs: [],
    managers: [],
  },
})
export class MenusState {
  @Selector()
  static currentMenuSidebar(state: MenusModel) {
    return {
      sidebar: state.sidebar,
      configs: state.configs,
      managers: state.managers,
    };
  }

  @Selector()
  static currentMenuTopbar(state: MenusModel) {
    return state.topbar;
  }

  @Selector()
  static currentUserMenu(state: MenusModel) {
    return state.usermenu;
  }

  @Action(AddDataMenu)
  addDataMenu(context: StateContext<any>, action: AddDataMenu) {
    context.patchState({
      sidebar: action.dataMenu.sidebar,
      topbar: action.dataMenu.topbar,
      usermenu: action.dataMenu.usermenu,
      configs: action.dataMenu.configs,
      managers: action.dataMenu.managers,
    });
  }

  @Action(AddDataSidebar)
  addDataSidebar(context: StateContext<any>, action: AddDataSidebar) {
    context.patchState({
      sidebar: action.dataMenu,
    });
  }

  @Action(AddDataTopbar)
  addDataTopbar(context: StateContext<any>, action: AddDataTopbar) {
    context.patchState({
      topbar: action.dataMenu,
    });
  }

  @Action(AddDataUsermenu)
  addDataUserMenu(context: StateContext<any>, action: AddDataUsermenu) {
    context.patchState({
      usermenu: action.dataMenu,
    });
  }

  @Action(ResetMenuState)
  resetMenuState(context: StateContext<any>) {
    context.setState({
      sidebar: [],
      topbar: [],
      usermenu: [],
      configs: [],
      managers: [],
    });
  }
}
