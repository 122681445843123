import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UsersManagmentService } from 'app/core-lib/services/users-managment/users-managment.service';
import { CustomValidators } from 'app/shared/custom-validators';
import { UtilService } from 'app/shared/util.service';
import PhoneCodesWorldWide from 'app/shared/utils/phones-codes-world-wide';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { RolesActionsModalComponent } from '../../roles-actions-modal/roles-actions-modal.component';
import { RestoreAccessComponent } from '../../actions/restore-access/restore-access.component';

@Component({
  selector: 'mh-personal-information',
  templateUrl: './personal-information.component.html',
})
export class PersonalInformationComponent implements OnInit {
  @Input() data;
  @Input() isEdit = false;

  @Input() progressRate;
  @Input() personalAdjusts;

  @Output() validForm = new EventEmitter();
  @Output() updateUser = new EventEmitter();
  @Output() updateIndex = new EventEmitter();

  form: FormGroup;
  object = Object;
  roles = [];
  departments = [];
  languages = [];
  phonesCodes = PhoneCodesWorldWide;
  patchUser = false;
  image;
  subscription: Subscription;
  isSuperAdmin;
  rolesList: { id: number; name: string }[] = [];

  constructor(
    private fb: FormBuilder,
    private utilService: UtilService,
    private userManagementService: UsersManagmentService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.phonesCodes = this.phonesCodes.sort((a, b) => {
      const codePhoneA = parseFloat(a.code_phone.replace('-', ''));
      const codePhoneB = parseFloat(b.code_phone.replace('-', ''));
      return codePhoneA - codePhoneB;
    });
    this.isSuperAdmin = this.utilService.currentUser.isSuperAdmin();
    this.patchUser = this.data?.id;
    this.form = this.fb.group({
      name: [this.data?.first_name || this.data?.name || '', [Validators.required]],
      lastName: [this.data?.last_name || this.data?.lastName || '', [Validators.required]],
      cellphone: [this.data?.mobile_number || this.data?.cellphone || ''],
      cellphoneCountryCode: [this.data?.mobile_country_code || this.data?.cellphoneCountryCode || ''],
      birthdate: [
        (this.data?.birth_date && moment(this.data?.birth_date, 'YYYY-MM-DD').format('YYYY-MM-DD')) ||
          this.data?.birthdate ||
          '',
      ],
      email: [this.data?.email || this.data || '', [Validators.required, CustomValidators.email]],
      language: [this.data?.language_id || this.data?.language || 1, [Validators.required]],
      role: [this.data?.user_role?.id || ''],
      job: [this.data?.company_position, [Validators.required]],
      department: [this.data?.department?.id || '', [Validators.required]],
      jobType: [this.data?.role?.id],
      profilePhotoRaw: [this.data?.profile_photo || this.data?.profilePhotoRaw],
    });

    if (this.isSuperAdmin) {
      this.form.addControl('hubspot_id', new FormControl(this.data?.hubspot_id || ''));
    }

    if (this.form.get('email').value !== '') {
      this.form.get('email').disable();
    }

    if (this.personalAdjusts) {
      this.form.get('role').disable();
    }

    this.userManagementService.getRolesAndDeparments().subscribe((result: { roles: any[]; deparments: any[] }) => {
      const { roles, deparments } = result;
      this.roles = roles;
      this.departments = deparments;
    });

    this.userManagementService.getRoles().subscribe((result: { id: number; name: string }[]) => {
      this.rolesList = result;
    });

    this.languages = this.utilService.languages;
    if (this.patchUser) {
      this.validForm.emit(true);
    }
  }

  get controls() {
    const staticNonPublicControls = ['phoneCountryCode', 'cellphoneCountryCode', 'flagAdmin', 'profilePhotoRaw'];
    const nonPublicControls = this.isSuperAdmin ? staticNonPublicControls : [...staticNonPublicControls, 'pipedriveId'];
    return Object.keys(this.form.controls).filter((key) => !nonPublicControls.includes(key));
  }

  changeProfilePhoto(event) {
    this.image = event;
    this.form.get('profilePhotoRaw').setValue(this.image || '');
  }

  onClickToggle(toggle) {
    this.form.get('flagAdmin').setValue(toggle.value);
  }

  numbersOnly(event) {
    const regExp = /^([0-9])$/;
    const result = regExp.test(event.key);
    return result;
  }

  emitUser() {
    let userToEmit = {
      ...this.form.value,
      last_name: this.form.get('lastName').value || '',
      email: this.form.get('email').value || '',
      department: this.departments.find((department) => department.id === this.form.get('department').value),
      role: this.roles.find((role) => role.id === this.form.get('jobType').value),
      user_role: this.rolesList.find((role) => role.id === this.form.get('role').value),
      company_position: this.form.get('job').value,
      id: this.data.id,
      flag_admin: this.form.get('role').value === 1,
      mobile_number: this.form.get('cellphone').value,
      mobile_country_code: this.form.get('cellphoneCountryCode').value,
      flag_super_admin: this.data?.flag_super_admin,
      birth_date: this.form.get('birthdate').value || '',
      first_name: this.form.get('name').value || '',
      language_id: this.form.get('language').value || 1,
    };

    if (this.form.get('profilePhotoRaw').value?.data) {
      userToEmit = {
        ...userToEmit,
        profile_photo_raw: this.form.get('profilePhotoRaw').value || '',
      };
    }
    this.updateUser.emit(userToEmit);
  }

  setBirthdate(date) {
    this.form.get('birthdate').setValue(date);
  }

  updateIndexEmit(value) {
    this.emitUser();
    this.updateIndex.next(value);
  }

  openRolesActionsModal() {
    this.modalService.open(RolesActionsModalComponent, null, ['overlay-panel', 'xxlg', '!tw-h-auto']);
  }

  editPassword() {
    const modalComponent = RestoreAccessComponent;
    const modalClass = ['overlay-panel', '!tw-h-auto'];
    this.modalService.open(
      modalComponent,
      {
        data: {
          state: 2,
          user: { id: this.data.id, first_name: this.data.first_name, last_name: this.data.last_name },
          userIds: this.data.id,
        },
      },
      modalClass,
    );
  }
}
